import styled from 'styled-components';
import { Text } from '@womcorp/wom-ui-kit';
import { rem } from 'polished';

import { device } from '../../../utils/device';

import {
  IconContainerStyled,
  DescriptionHeaderTextStyled,
} from '../DescriptionSection/styled';

const WomLinksSectionWrapperStyled = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const NewClientLinksWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: ${rem('62px')} ${rem('54px')};
  padding-left: 0;
  background-color: ${(p) => p.theme.colors.lightGreyColor};
  order: 1;
  @media ${device.tablet} {
    order: 2;
    width: 100%;
    justify-content: center;
    padding: ${rem('11px')} ${rem('24px')} ${rem('72px')};
  }
`;

const NewClientLinksContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${rem('24px')} ${rem('16px')};
`;

const OffersWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
  padding: ${rem('81px')} ${rem('113px')};
  background: ${(p) => p.theme.colors.primaryColor};
  order: 2;
  @media ${device.tablet} {
    order: 1;
    width: 100%;
    padding: ${rem('42px')} ${rem('40px')};
    justify-content: center;
  }
`;

const OffersContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const OffersDescriptionHeaerTextStyled = styled(DescriptionHeaderTextStyled)`
  text-shadow: 1px 1px #7c6c8a, -1px -1px 0 #7c6c8a, 1px -1px 0 #7c6c8a,
    -1px 1px 0 #7c6c8a;
  margin-bottom: ${rem('36px')};
  color: ${(p) => p.theme.colors.whiteColor};
`;

const OffersIconContiainerStyled = styled(IconContainerStyled)`
  width: ${rem('70px')};
  height: ${rem('70px')};
  margin-bottom: ${rem('24px')};
  margin-right: 0;
`;

const OffersButtonStyled = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${rem('12px')} ${rem('25px')};
  position: static;
  width: ${rem('153px')};
  height: ${rem('40px')};
  left: ${rem('94.5px')};
  top: ${rem('238px')};
  background: ${(p) => p.theme.colors.accentColor};
  border-radius: ${rem('8px')};
  flex: none;
  order: 2;
  flex-grow: 0;
`;

const OffersButtonTextStyled = styled(Text)`
  color: ${(p) => p.theme.colors.whiteColor};
  white-space: nowrap;
  margin-bottom: 0;
  font-weight: 500;
`;

export {
  OffersButtonStyled,
  OffersButtonTextStyled,
  OffersIconContiainerStyled,
  WomLinksSectionWrapperStyled,
  NewClientLinksContainerStyled,
  NewClientLinksWrapperStyled,
  OffersDescriptionHeaerTextStyled,
  OffersContainerStyled,
  OffersWrapperStyled,
};
