import React from 'react';

import StoreLink from './StoreLink/StoreLink';

import {
  AppDownloadsHeaderTextStyled,
  AppDownloadsSectionStyled,
  StoreLinksContainerStyled,
} from './styled';

import {
  AppStorePng,
  GooglePlayPng,
  AppGalleryPng,
} from '../../../assets/images/newLanding';

const AppDownloadsSection = () => (
  <AppDownloadsSectionStyled>
    <AppDownloadsHeaderTextStyled>
      Descarga nuestra APP
    </AppDownloadsHeaderTextStyled>
    <StoreLinksContainerStyled>
      <StoreLink
        img={AppStorePng}
        link="https://apps.apple.com/cl/app/wom/id1192713536"
      />
      <StoreLink
        img={GooglePlayPng}
        link="https://play.google.com/store/apps/details?id=cl.wom.transformacion.appwommobile&hl=en&gl=US"
      />
      <StoreLink
        img={AppGalleryPng}
        link="https://apkapp.gallery/dl/101187175/"
      />
    </StoreLinksContainerStyled>
  </AppDownloadsSectionStyled>
);
export default AppDownloadsSection;
