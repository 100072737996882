import React from 'react';

import {
  DescriptionStyled,
  IconContainerStyled,
  IconStyled,
  DescriptionTextStyled,
} from '../styled';

const Description = ({ text, icon }) => (
  <DescriptionStyled>
    <IconContainerStyled>
      <IconStyled alt="text" src={icon} />
    </IconContainerStyled>
    <DescriptionTextStyled>{text}</DescriptionTextStyled>
  </DescriptionStyled>
);

export default Description;
