import React from 'react';
import { Button } from '@womcorp/wom-ui-kit';

import {
  SaveShortcutStyled,
  ButtonIconContainerStyled,
  ButtonIconTextWrapperStyled,
  ButtonTextContainerStyled,
} from './styled';
import { StarIcon } from '../../../assets/images/newLanding';

const SaveShortcut = () => (
  <SaveShortcutStyled>
    <Button type="submit" variant="primary" size="full" full>
      <ButtonIconTextWrapperStyled>
        <ButtonIconContainerStyled src={StarIcon} alt="Star" />
        <ButtonTextContainerStyled>
          GUARDA EL ACCESO DIRECTO
        </ButtonTextContainerStyled>
      </ButtonIconTextWrapperStyled>
    </Button>
  </SaveShortcutStyled>
);

export default SaveShortcut;
