import React from 'react';

import SaveShortcut from '../SaveShortcut/SaveShortcut';

import {
  WelcomeContainerStyled,
  WelcomeHeaderStyled,
  WelcomeStyled,
  WelcomeTextStyled,
  WelcomeWrapperStyled,
  HeaderTextStyled,
  LoginCardStyled,
  LoginCardTextStyled,
  CardContainerStyled,
} from './styled';

const WelcomeSection = () => (
  <WelcomeWrapperStyled>
    <WelcomeContainerStyled>
      <WelcomeStyled>
        <WelcomeHeaderStyled>Bienvenido a MIWOM</WelcomeHeaderStyled>
        <SaveShortcut />
        <WelcomeTextStyled>Y llévalo a todos lados</WelcomeTextStyled>
      </WelcomeStyled>
    </WelcomeContainerStyled>
    <CardContainerStyled>
      <WelcomeHeaderStyled>Bienvenido a MIWOM</WelcomeHeaderStyled>
      <LoginCardStyled>
        <HeaderTextStyled>¡Hola WOMER!</HeaderTextStyled>
        <LoginCardTextStyled>
          Para ingresar a tu cuenta puedes hacerlo con tu número WOM o tu email
          asociado
        </LoginCardTextStyled>
      </LoginCardStyled>
      <WelcomeStyled>
        <SaveShortcut />
        <WelcomeTextStyled>Y llévalo a todos lados</WelcomeTextStyled>
      </WelcomeStyled>
    </CardContainerStyled>
  </WelcomeWrapperStyled>
);

export default WelcomeSection;
