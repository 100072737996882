import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '@womcorp/wom-ui-kit';

import { device } from '../../../utils/device';

const DescriptionWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const SampleImagesWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 53%;
  margin-bottom: auto;
  order: 1;
  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 0;
    order: 2;
    margin-top: ${rem('40px')};
  }
`;

const DescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 47%;
  padding-left: ${rem('88px')};
  padding-top: ${rem('15px')};
  order: 2;
  @media ${device.tablet} {
    width: 100%;
    padding-left: 0;
    order: 1;
    text-align: left;
    align-items: center;
    padding-top: ${rem('40px')};
  }
`;

const PlanSampleImagesContainerStyled = styled.img`
  display: flex;
  align-items: flex-end;
  max-width: ${rem('450px')};
  width: 100%;
  @media ${device.tablet} {
    padding: 0 ${rem('34px')};
    max-width: 100%;
  }
`;

const IconStyled = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const DescriptionTextStyled = styled(Text)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: ${rem('2px')};
`;

const DescriptionHeaderTextStyled = styled(Text)`
  font-size: ${rem('26px')};
  font-weight: 700;
  white-space: nowrap;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  & span {
    line-height: ${rem('32px')};
  }
  @media ${device.tablet} {
    margin-bottom: ${rem('40px')};
  }
`;

const DescriptionStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem('18px')};
  justify-content: flex-start;
  @media ${device.tablet} {
    width: ${rem('300px')};
  }
`;
const IconContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('24px')};
  height: ${rem('24px')};
  margin-right: ${rem('8px')};
`;

export {
  IconContainerStyled,
  DescriptionContainerStyled,
  DescriptionHeaderTextStyled,
  DescriptionStyled,
  DescriptionTextStyled,
  DescriptionWrapperStyled,
  SampleImagesWrapperStyled,
  PlanSampleImagesContainerStyled,
  IconStyled,
};
