import styled from 'styled-components';

const LandingStyled = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  background-color: ${(p) => p.theme.colors.ghostWhiteColor};
`;

export default LandingStyled;
