import styled from 'styled-components';
import { rem } from 'polished';

const SaveShortcutStyled = styled.div`
  flex: 1 1 1;
`;

const ButtonIconTextWrapperStyled = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
`;
const ButtonTextContainerStyled = styled.div`
  margin-left: ${rem('7px')};
`;

const ButtonIconContainerStyled = styled.img`
  display: flex;
  align-items: center;
`;

export {
  SaveShortcutStyled,
  ButtonIconContainerStyled,
  ButtonTextContainerStyled,
  ButtonIconTextWrapperStyled,
};
