import React from 'react';

import PlanButton from './PlanButton/PlanButton';

import {
  OffersButtonStyled,
  OffersButtonTextStyled,
  OffersIconContiainerStyled,
  WomLinksSectionWrapperStyled,
  NewClientLinksContainerStyled,
  NewClientLinksWrapperStyled,
  OffersDescriptionHeaerTextStyled,
  OffersContainerStyled,
  OffersWrapperStyled,
} from './styled';

import {
  DescriptionTextStyled,
  DescriptionHeaderTextStyled,
  IconStyled,
} from '../DescriptionSection/styled';

import {
  TwoSmartphonesIcon,
  MobileBandwidthIcon,
  FiberIcon,
  HandHoldingPhoneIcon,
} from '../../../assets/images/newLanding';

const WomLinksSection = () => (
  <WomLinksSectionWrapperStyled>
    <NewClientLinksWrapperStyled>
      <NewClientLinksContainerStyled>
        <DescriptionHeaderTextStyled>
          <span>Si no eres cliente</span>
          <span>pórtate aquí</span>
        </DescriptionHeaderTextStyled>
        <DescriptionTextStyled>
          Encuentra un plan a tu medida
        </DescriptionTextStyled>
        <PlanButton
          planIcon={TwoSmartphonesIcon}
          text="TELEFONÍA MÓVIL"
          link="https://store.wom.cl/planes/"
        />
        <PlanButton
          planIcon={MobileBandwidthIcon}
          text="BANDA ANCHA MÓVIL"
          link="https://www.wom.cl/internet"
        />
        <PlanButton
          planIcon={FiberIcon}
          text="INTERNET FIBRA"
          link="https://www.wom.cl/internet-fibra-optica/"
        />
      </NewClientLinksContainerStyled>
    </NewClientLinksWrapperStyled>
    <OffersWrapperStyled>
      <OffersContainerStyled>
        <OffersIconContiainerStyled>
          <IconStyled src={HandHoldingPhoneIcon} alt="hand holding phone" />
        </OffersIconContiainerStyled>
        <OffersDescriptionHeaerTextStyled>
          <span>Revisa las ofertas en</span>
          <span>equipos que tenemos</span>
          <span>para ti</span>
        </OffersDescriptionHeaerTextStyled>
        <OffersButtonStyled
          href="https://store.wom.cl/equipos"
          target="_blank"
          rel="noreferrer"
        >
          <OffersButtonTextStyled>VER EQUIPOS</OffersButtonTextStyled>
        </OffersButtonStyled>
      </OffersContainerStyled>
    </OffersWrapperStyled>
  </WomLinksSectionWrapperStyled>
);
export default WomLinksSection;
