import React from 'react';

import StoreImgContainerStyled from './styled';
import { IconStyled } from '../../DescriptionSection/styled';

const StoreLink = ({ img, link }) => (
  <StoreImgContainerStyled href={link} target="_blank" rel="noreferrer">
    <IconStyled src={img} alt={link} />
  </StoreImgContainerStyled>
);

export default StoreLink;
