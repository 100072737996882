import styled from 'styled-components';
import { Text } from '@womcorp/wom-ui-kit';
import { rem } from 'polished';
import { device } from '../../../utils/device';

const AppDownloadsSectionStyled = styled.div`
  width: 100%;
  padding: ${rem('80px')} ${rem('311px')} ${rem('121px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.whiteColor};
  @media ${device.tablet} {
    flex-direction: column;
    padding: ${rem('48px')} ${rem('40px')} ${rem('140px')};
  }
`;

const AppDownloadsHeaderTextStyled = styled(Text)`
  font-size: ${rem('26px')};
  font-weight: 700;
  white-space: nowrap;
  word-break: keep-all;

  line-height: ${rem('32px')};
  @media ${device.tablet} {
    font-size: ${rem('16px')};
    line-height: ${rem('20px')};
    display: flex;
    align-items: center;
  }
`;

const StoreLinksContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  AppDownloadsHeaderTextStyled,
  AppDownloadsSectionStyled,
  StoreLinksContainerStyled,
};
