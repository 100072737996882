import React from 'react';

import Header from '../Header/Header';
import WelcomeSection from '../WelcomeSection/WelcomeSection';
import DescriptionSection from '../DescriptionSection/DescriptionSection';
import WomlinksSection from '../WomLinksSection/WomLinksSection';
import AppDownloadsSection from '../AppDownloadsSection/AppDownloadsSection';

import LandingStyled from './styled';

const Landing = () => (
  <LandingStyled>
    <Header />
    <WelcomeSection />
    <DescriptionSection />
    <WomlinksSection />
    <AppDownloadsSection />
  </LandingStyled>
);

export default Landing;
