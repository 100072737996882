import styled from 'styled-components';
import { rem } from 'polished';
import { Text } from '@womcorp/wom-ui-kit';

import { IconContainerStyled } from '../../DescriptionSection/styled';

const PlanButtonContainerStyled = styled.a`
  position: static;
  width: ${rem('280px')};
  height: ${rem('48px')};
  background: ${(p) => p.theme.colors.whiteColor};
  border: ${rem('1px')} solid ${(p) => p.theme.colors.primaryColor};
  box-sizing: border-box;
  border-radius: ${rem('8px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${rem('16px')} ${rem('18px')};
  margin-top: ${rem('16px')};
`;

const ChevronContainerStyled = styled(IconContainerStyled)`
  margin-left: auto;
  width: auto;
  margin-right: 0;
`;

const PlanTextStyled = styled(Text)`
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: ${rem('2px')};
`;

export { PlanButtonContainerStyled, PlanTextStyled, ChevronContainerStyled };
