import React from 'react';

import { Waves } from '../../index';

import { LogoWomImg } from '../../../assets/images/newLanding';
import {
  HeaderContainerStyled,
  LinkContainerStyled,
  HeaderWrapperStyled,
  WomClLinkStyled,
  LogoContainerStyled,
  LogoNavStyled,
  InclinedDivStyled,
  WavesContainerStyled,
  WavesWrapperStyled,
  WavesSpaceStyled,
} from './styled';

const Header = () => (
  <HeaderWrapperStyled>
    <InclinedDivStyled>
      <WavesWrapperStyled>
        <WavesContainerStyled>
          <WavesSpaceStyled />
          <Waves upsideDown />
        </WavesContainerStyled>
      </WavesWrapperStyled>
    </InclinedDivStyled>
    <HeaderContainerStyled>
      <LogoContainerStyled>
        <LogoNavStyled src={LogoWomImg} alt="WOM" />
      </LogoContainerStyled>
      <LinkContainerStyled>
        <WomClLinkStyled
          href="https://www.wom.cl"
          target="_blank"
          rel="noreferrer"
        >
          Volver a WOM.cl
        </WomClLinkStyled>
      </LinkContainerStyled>
    </HeaderContainerStyled>
  </HeaderWrapperStyled>
);

export default Header;
