import React from 'react';

import {
  PlanButtonContainerStyled,
  PlanTextStyled,
  ChevronContainerStyled,
} from './styled';

import {
  IconContainerStyled,
  IconStyled,
} from '../../DescriptionSection/styled';

import { ChevronRight } from '../../../../assets/images/newLanding';

const PlanButton = ({ planIcon, text, link }) => (
  <PlanButtonContainerStyled href={link} target="_blank" rel="noreferrer">
    <IconContainerStyled>
      <IconStyled src={planIcon} alt={text} />
    </IconContainerStyled>
    <PlanTextStyled>{text}</PlanTextStyled>
    <ChevronContainerStyled>
      <IconStyled alt="chevron right" src={ChevronRight} />
    </ChevronContainerStyled>
  </PlanButtonContainerStyled>
);

export default PlanButton;
