import React from 'react';

import Description from './Description/Description';

import {
  DescriptionContainerStyled,
  DescriptionHeaderTextStyled,
  DescriptionWrapperStyled,
  SampleImagesWrapperStyled,
  PlanSampleImagesContainerStyled,
} from './styled';

import {
  PlanSamplesPng,
  TwoSmartphonesIcon,
  RechargeBalanceIcon,
  PaymentIcon,
  ChangePlanIcon,
  ShoppingCartIcon,
  HornsHandIcon,
} from '../../../assets/images/newLanding';

const DescriptionSection = () => (
  <DescriptionWrapperStyled>
    <SampleImagesWrapperStyled>
      <PlanSampleImagesContainerStyled alt="Samples" src={PlanSamplesPng} />
    </SampleImagesWrapperStyled>
    <DescriptionContainerStyled>
      <DescriptionHeaderTextStyled>
        <span>Maneja tu cuenta fácil</span>
        <span>y rápido</span>
      </DescriptionHeaderTextStyled>
      <Description
        icon={TwoSmartphonesIcon}
        text="Consulta todos tus servicios"
      />
      <Description icon={RechargeBalanceIcon} text="Recarga saldo" />
      <Description icon={PaymentIcon} text="Paga tu cuenta" />
      <Description icon={ChangePlanIcon} text="Cambia de plan" />
      <Description
        icon={ShoppingCartIcon}
        text="Contrata servicios adicionales"
      />
      <Description icon={HornsHandIcon} text="Y mucho más" />
    </DescriptionContainerStyled>
  </DescriptionWrapperStyled>
);

export default DescriptionSection;
