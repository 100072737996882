import styled from 'styled-components';
import { rem } from 'polished';

import { device, size } from '../../../utils/device';

const HeaderWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${rem('49px')} 0;
  z-index: 2;
  position: relative;
  background: ${(p) => p.theme.colors.primaryColor};
  @media ${device.tablet} {
    padding: 0;
  }
`;
const HeaderContainerStyled = styled.div`
  max-width: ${size.laptopL};
  width: 100%;
  min-height: ${rem('60px')};
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  align-items: center;
  z-index: 97;
  padding: 0 ${rem('115px')};
  @media ${device.tablet} {
    padding: ${rem('16px')};
  }
`;
const LogoContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    width: auto;
    padding: 0;
  }
`;
const LogoNavStyled = styled.img`
  transition: transform 0.3s;
  cursor: pointer;
  &:hover {
    transform: rotate(180deg);
  }
`;
const LinkContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${device.tablet} {
    padding: 0px;
  }
`;

const WomClLinkStyled = styled.a`
  color: ${(p) => p.theme.colors.whiteColor};
  display: block;
`;

const InclinedDivStyled = styled.div`
  transform: rotate(-6deg);
  width: 150vw;
  position: absolute;
  top: 0;
  z-index: -1;
  margin-left: -50%;
  @media ${device.tablet} {
    transform: none;
  }
`;

const WavesContainerStyled = styled.div`
  position: absolute;
  width: 110%;
  background-color: ${(p) => p.theme.colors.primaryColor};
`;

const WavesSpaceStyled = styled.div`
  width: 1px;
  height: ${rem('800px')};
  @media ${device.tablet} {
    height: ${rem('300px')};
  }
`;

const WavesWrapperStyled = styled.header`
  background-color: ${(p) => p.theme.colors.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export {
  HeaderContainerStyled,
  LinkContainerStyled,
  HeaderWrapperStyled,
  WomClLinkStyled,
  LogoContainerStyled,
  LogoNavStyled,
  InclinedDivStyled,
  WavesContainerStyled,
  WavesSpaceStyled,
  WavesWrapperStyled,
};
