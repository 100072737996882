import styled from 'styled-components';
import { Text, Card } from '@womcorp/wom-ui-kit';
import { rem } from 'polished';

import { device } from '../../../utils/device';

const WelcomeHeaderStyled = styled.div`
  position: static;
  width: 291px;
  height: 96px;
  left: 0px;
  top: 0px;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.colors.whiteColor};
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: ${rem('30px')} 0 ${rem('16px')};
  font-weight: 600;
`;

const WelcomeTextStyled = styled.div`
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.colors.whiteColor};
  margin: ${rem('7px')} 0;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: bold;
`;

const HeaderTextStyled = styled(Text)`
  font-size: ${rem('20px')};
  font-weight: 500;
`;

const WelcomeWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: ${rem('100px')};
  padding: 0 ${rem('85px')};
  @media ${device.tablet} {
    padding: 0 ${rem('24px')};
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const WelcomeStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${rem('300px')};
  margin-left: auto;
`;

const LoginCardStyled = styled(Card)`
  width: ${rem('390px')};
  height: ${rem('346px')};
  padding: ${rem('24px')};
`;

const LoginCardTextStyled = styled(Text)`
  color: ${(p) => p.theme.colors.greyColor};
`;

const WelcomeContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  z-index: 2;
  margin-right: ${rem('65px')};
  @media ${device.tablet} {
    width: 100%;
    padding: 0 ${rem('24px')};
    justify-content: center;
    ${WelcomeStyled} {
      display: none;
    }
  }
`;

const CardContainerStyled = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  margin-left: ${rem('65px')};
  z-index: 2;
  ${WelcomeHeaderStyled} {
    display: none;
  }
  ${WelcomeStyled} {
    display: none;
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 0 ${rem('24px')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    ${WelcomeHeaderStyled} {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 32px;
      left: 24px;
      top: 88px;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      font-weight: 400;
    }
    ${WelcomeStyled} {
      display: flex;
      margin-left: 0;
    }
    ${WelcomeTextStyled} {
      color: ${(p) => p.theme.colors.primaryColor};
      font-weight: normal;
    }
    ${LoginCardStyled} {
      width: 100%;
    }
  }
`;

export {
  WelcomeStyled,
  WelcomeContainerStyled,
  WelcomeWrapperStyled,
  WelcomeHeaderStyled,
  WelcomeTextStyled,
  LoginCardStyled,
  HeaderTextStyled,
  LoginCardTextStyled,
  CardContainerStyled,
};
