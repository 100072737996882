import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import Landing from '../../components/newLanding/Landing/Landing';

export const theme = {
  colors: {
    primaryColor: '#2D1441',
    whiteColor: '#FFFFFF',
    lightGreyColor: '#e6e2e8',
    buttonPrimary: '#E92070',
    buttonPrimaryDisabled: '#CCC4D2',
    buttonSecondary: '#381451',
    buttonSecondaryDisabled: '#381451',
    accentColor: '#E92070',
    ghostWhiteColor: '#f4f4f7',
  },
  fonts: {
    primaryFont: 'cerapro',
    secondaryFont: 'SFProText',
  },
};

const GlobalStyle = createGlobalStyle`
  html{
    background: #F1F0F3;
    font-family: 'cerapro';
  }
`;

const newLanding = () => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Landing />
      </ThemeProvider>
    </>
  );
};

export default newLanding;
