import styled from 'styled-components';
import { rem } from 'polished';
import { device } from '../../../../utils/device';

const StoreImgContainerStyled = styled.a`
  max-height: ${rem('45px')};
  margin-left: ${rem('24px')};
  margin-bottom: ${rem('16px')};
  height: 100%;
  @media ${device.tablet} {
    margin: ${rem('2px')};
  }
  img {
    max-height: ${rem('45px')};
    @media ${device.tablet} {
      max-height: ${rem('35px')};
    }
  }
`;

export default StoreImgContainerStyled;
